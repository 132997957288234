import React from 'react';
import loading from '../../images/loading.gif';
import './loading.css';

const Loading = () => {
    return (
        <div className="loader-wrapper" >
            <img src={loading}></img>
        </div>
    );
}

export default Loading;