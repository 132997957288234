import { createSlice } from '@reduxjs/toolkit';
import { formatFileName } from '../libs/comon';
import { I18n } from 'aws-amplify';
import _ from 'lodash';

export const UploadInfoSlice = createSlice({
    name: 'UploadInfo',
    initialState: {
        //upload info
        billFolder: '',
        batchFolder: `${new Date().getTime()}`,
        files: [],
        currentProcessState: 'Ready to Upload',
        btnClass: '',
        billFormats: [],

        //flags
        filesUploaded: 0,
        allFilesUploaded: false,
        startUpload: false,
        executionArn: false,
        startConvert: false,
        convertCount: 0,
        isErrorInFileSelection: 'none',
        freeze: false,
        fullyFailed: false,
        fullyConverted: false,
        processOngoing: false  
    },
    reducers: {
        //upload info
        isSetFiles: (state, action) => {
            state.files = action.payload
            //console.log'Files before state update', state.files)
            if(state.files.length === 0) {
                //console.log'Error entering')
                state.isErrorInFileSelection = 'none';
            }
            //console.logstate.files + ' files updated at state!')
        },
        isSetAllBillFormats: (state, action) => {
            state.billFormats = action.payload
        },
        isSetBillFolder: (state, action) => {
            state.billFolder = action.payload
            //console.logstate.billFolder + ' Bill folder updated at state!')
        },
        isSetBatchFolder: (state, action) => {
            state.batchFolder = action.payload
            //console.logstate.batchFolder + ' Batch folder updated at state!')
        },

        //flag handlers
        isSetFilesUploaded: (state, action) => {
            state.filesUploaded += action.payload
            state.processOngoing = true
            //console.logstate.filesUploaded + ' filesUploaded flag updated at state!')
            if(state.filesUploaded === state.files.length) {
                state.allFilesUploaded = true
                // state.alreadyUploaded
                state.startUpload = false
                state.currentProcessState = 'Converting'
                state.startConvert = true
                state.btnClass = 'is-info'
                //console.logstate.allFilesUploaded + ' AllFilesUploaded updated at state!')
            }
        },
        isSetStartUpload: (state, action) => {
            state.startUpload = action.payload
            // state.alreadyProcessed = true
            state.isErrorInFileSelection = 'none'
            state.currentProcessState = 'Uploading'
            state.btnClass = 'is-warning'
            //console.logstate.startUpload + ' StartUpload updated at state!')
        },
        isSetExecutionArn: (state, action) => {
            state.executionArn = action.payload
            //console.logstate.executionArn + ' executionArn updated at state!')
        },
        isSetAddConvert: (state, action) => {
            state.currentProcessState = state.convertCount
            state.startConvert = true
            state.convertCount = action.payload
            state.files.map(file => {
                file.converting += 1
                return file
            })
            //console.logstate.startConvert + ' StartConvert updated at state!')
            //console.logstate.convertCount + ' ConvertCount updated at state!')
        },

        //cleanup
        cleanup: (state) => {
            state.files = []
            state.batchFolder = `${new Date().getTime()}`
            state.filesUploaded = 0
            state.allFilesUploaded = false
            state.startUpload = false
            state.executionArn = false
            state.startConvert = false
            state.convertCount = 0
            state.currentProcessState = 'Ready to Upload'
            state.btnClass = ''
            state.isErrorInFileSelection = 'none'
            state.freeze = false
            state.fullyFailed = false
            state.fullyConverted = false
            state.processOngoing = false
            // state.alreadyProcessed = false
        },

        freezeState: (state) => {
            state.freeze = true
        },
        isSetFullyFailed: (state) => {
            state.fullyFailed = true
        },
        isSetFullyConverted: (state) => {
            state.fullyConverted = true
        },
        isSetFileStatus: (state, action) => {
            state.files.map(e => {
                //console.log'file name', formatFileName(e.file.name), 'incoming name', action.payload)
                if(action.payload.includes(formatFileName(e.file.name))) {
                    e.converted = false
                    //console.log'convert updated')
                    return e
                } else {
                    e.converted = true
                    //console.log'convert updated true')
                    return e
                }
            })
             
        },
        isSetCurrentProcessState: (state, action) => {
            state.currentProcessState = action.payload
            //console.log'CurrentProcess state updated!')
        },
        isSetErrorInFileSelection: (state, action) => {
            state.isErrorInFileSelection = action.payload
            //console.log'isErrorInFileSelection state updated!')
        },
        isRemoveFile: (state, action) => {
            //console.log'Action for remove file', action)
            state.files = state.files.filter(e => e.file.name !== action.payload)
            if(state.files.length <= 5) {
                state.isErrorInFileSelection = 'none';
            }
            //console.log'after remove files', state.files)
        }
    }
})

export const { 
    //upload info
    isSetFiles,
    isSetBillFolder,
    isSetBatchFolder,

    //flag Handlers
    isSetFilesUploaded,
    isSetStartUpload,
    isSetExecutionArn,
    isSetAddConvert,
    isSetFileStatus,
    cleanup,
    isSetCurrentProcessState,
    isSetErrorInFileSelection,
    isRemoveFile,
    freezeState,
    isSetAllBillFormats,
    isSetFullyFailed,
    isSetFullyConverted
} = UploadInfoSlice.actions

export default UploadInfoSlice.reducer