import React, { Suspense } from 'react';
import { Auth } from '@aws-amplify/auth';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AuthConfig } from './aws-exports';
import {
    HashRouter,
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentSession } from './auth/get-current-session';
import PublicRoute from './components/router/public-router';
import PrivateRoute from './components/router/private-router';
import { isSetUser, isSetGuest, isSetUsername } from './store/user-info';
import Loading from './components/loader/loading';
import TaskHistory from './pages/task-history';
import ContactUs from './pages/contact-us';
import { I18n } from 'aws-amplify';
import { dict } from './language';
import WhatsappFloatLink from './components/whatsapp-float-link/whatsapp-float-link';
import { Translations } from '@aws-amplify/ui-components';
import { isSetAllBillFormats } from './store/upload-info';


I18n.putVocabulariesForLanguage('pt', {
    [Translations.SIGN_IN_HEADER_TEXT]: 'Entre na sua conta',
    [Translations.SIGN_IN_ACTION]: 'ENTRAR',
    [Translations.SIGN_IN_TEXT]: 'ENTRAR',
    [Translations.RESET_PASSWORD_TEXT]: 'Resetar senha',
    [Translations.RESET_YOUR_PASSWORD]: 'Redefina sua senha',
    [Translations.FORGOT_PASSWORD_TEXT]: 'Esqueceu sua senha?',
    [Translations.USERNAME_LABEL]: 'Nome do usuário *',
    [Translations.USERNAME_PLACEHOLDER]: 'entre com seu nome de usuário',
    [Translations.PASSWORD_LABEL]: 'Senha *',
    [Translations.PASSWORD_PLACEHOLDER]: 'Coloque sua senha',
    [Translations.CODE_LABEL]: 'Código de verificação',
    [Translations.CODE_PLACEHOLDER]: 'Coloque o código',
    [Translations.NEW_PASSWORD_LABEL]: 'Nova Senha',
    [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Digite sua nova senha',
    [Translations.BACK_TO_SIGN_IN]: 'Voltar para Entrar',
    [Translations.SUBMIT]: 'Enviar',
    [Translations.SEND_CODE]: 'Enviar código',
    [Translations.SIGN_OUT]: 'Sair',
    //   FORGOT_PASSWORD_TEXT = 'Forgot your password?',
});


const HomePage = React.lazy(() => import('./pages/home-page'))
const AppHome = React.lazy(() => import('./pages/app-home'))

Auth.configure(AuthConfig);
const configurationOptions = {
    signInConfig: {
        isSignUpDisplayed: false
    }
};
// AWS.config.update({
//     region: 'us-east-1',
//     accessKeyId: Auth.cu
// })

const AuthStateApp = () => {
    const language = useSelector(state => state.userInfo.language)
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    const [fetchingSession, setFetchingSession] = React.useState(true)
    const dispatch = useDispatch()

    React.useEffect(() => {

    }, [language])

    React.useEffect(() => {

    }, [])


    React.useEffect(() => {
        const userLang = navigator.language || navigator.userLanguage;
        if (Object.keys(dict).includes(userLang.substr(0, 2))) {
            I18n.setLanguage(userLang.substr(0, 2))
        } else {
            I18n.setLanguage('pt')
        }
    }, [])

    React.useEffect(() => {

        return onAuthUIStateChange(async (nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
            if (authData) {
                //console.log'Setting auth mode in app')
                // const userdata = await Auth.userAttributes()
                // console.log('User Attr', userdata)
                dispatch(isSetUser(authData.attributes.sub))
                dispatch(isSetUsername(authData.username))
                dispatch(isSetAllBillFormats([]))
            } else {
                //console.log'Setting guest mode in app')
                const { user } = await getCurrentSession()
                dispatch(isSetGuest(user.guestId))
                dispatch(isSetAllBillFormats([]))
            }
            setFetchingSession(false)
        });
    }, []);

    React.useEffect(() => {
        setFetchingSession(true)
        return (async () => {
            const { session, user } = await getCurrentSession()
            if (session !== null) {
                // const userdata = await Auth.userAttributes()
                // console.log('User Attr', userdata)
                setAuthState('signedin')
                setUser(user)
                dispatch(isSetUser(user.attributes.sub))
                dispatch(isSetUsername(user.username))
                dispatch(isSetAllBillFormats([]))
            } else {
                dispatch(isSetGuest(user.guestId))
                dispatch(isSetAllBillFormats([]))
            }
            setFetchingSession(false)
        })()
    }, [])

    return (
        <>
            <HashRouter>
                <Switch>
                    <Route
                        // exact
                        path="/home"
                        render={({ match: { url } }) => (

                            <>
                                {
                                    fetchingSession === true
                                        ? <Loading></Loading>
                                        : authState === AuthState.SignedIn && user
                                            ? <Redirect to="/app" />
                                            : <Redirect to="/home" />
                                }
                                <PublicRoute exact path={`${url}/`} defaultRedirect={'/app'}>
                                    <Suspense fallback={<Loading></Loading>}><HomePage /></Suspense>
                                    {/* <Suspense fallback={<Loading></Loading>}><AdminApp /></Suspense> */}
                                    <WhatsappFloatLink />
                                </PublicRoute>
                                <PublicRoute exact path={`${url}/pricing`} defaultRedirect={'/app'}>
                                    <Suspense fallback={<Loading></Loading>}><HomePage /></Suspense>
                                    <WhatsappFloatLink />
                                </PublicRoute>
                                <PublicRoute exact path={`${url}/faqs`} defaultRedirect={'/app'}>
                                    <Suspense fallback={<Loading></Loading>}><HomePage /></Suspense>
                                    <WhatsappFloatLink />
                                </PublicRoute>
                                <PublicRoute exact path={`${url}/signin`} defaultRedirect={'/app'}>
                                    <AmplifyAuthenticator>
                                        <AmplifySignIn slot="sign-in"
                                            headerText={`${I18n.get('Sign in to your account')}`}
                                        // submitButtonText={I18n.get('SIGN IN')}
                                        >
                                            <div slot="secondary-footer-content"></div>
                                        </AmplifySignIn>
                                    </AmplifyAuthenticator>
                                </PublicRoute>
                                <PublicRoute exact path={`${url}/contactus`} defaultRedirect={'/app'}>
                                    <Suspense fallback={<Loading></Loading>}><ContactUs /></Suspense>
                                    <WhatsappFloatLink />
                                </PublicRoute>
                            </>
                        )}
                    />
                    <Route
                        // exact
                        path="/app"
                        render={({ match: { url } }) => (

                            <>
                                {
                                    fetchingSession === true
                                        ? <Loading></Loading>
                                        : authState === AuthState.SignedIn && user
                                            ? <Redirect to="/app" />
                                            : <Redirect to="/home" />
                                }
                                <PrivateRoute exact path={`${url}/`} defaultRedirect={'/home'}>
                                    <Suspense fallback={<Loading></Loading>}><AppHome /></Suspense>
                                    <WhatsappFloatLink />
                                </PrivateRoute>
                                <PrivateRoute exact path={`${url}/pricing`} defaultRedirect={'/home'}>
                                    <Suspense fallback={<Loading></Loading>}><AppHome /></Suspense>
                                    <WhatsappFloatLink />
                                </PrivateRoute>
                                <PrivateRoute exact path={`${url}/task-history`} defaultRedirect={'/home'}>
                                    <Suspense fallback={<Loading></Loading>}>
                                        <TaskHistory></TaskHistory>
                                    </Suspense>
                                    <WhatsappFloatLink />
                                </PrivateRoute>
                                <PrivateRoute exact path={`${url}/contactus`} defaultRedirect={'/home'}>
                                    <Suspense fallback={<Loading></Loading>}><ContactUs /></Suspense>
                                    <WhatsappFloatLink />
                                </PrivateRoute>
                                <PrivateRoute exact path={`${url}/faqs`} defaultRedirect={'/home'}>
                                    <Suspense fallback={<Loading></Loading>}><AppHome /></Suspense>
                                    <WhatsappFloatLink />
                                </PrivateRoute>

                            </>
                        )}
                    />
                    {/* <Route
                        // exact
                        path="/home"
                        render={({ match: { url } }) => (

                            <>
                                {
                                    fetchingSession === true
                                        ? <Loading></Loading>
                                        : authState === AuthState.SignedIn && user
                                            ? <Redirect to="/app" />
                                            : <Redirect to="/home" />
                                }
                                <PrivateRoute exact path={`${url}/`} defaultRedirect={'/home'}>
                                    <Suspense fallback={<Loading></Loading>}><AppHome /></Suspense>
                                    <WhatsappFloatLink />
                                </PrivateRoute>
                            </>
                        )}
                    /> */}
                    
                </Switch>
                {
                    fetchingSession === true
                        ? <Loading></Loading>
                        : authState === AuthState.SignedIn && user
                            ? <Redirect to="/app" />
                            : <Redirect to="/home" />
                }

            </HashRouter>
        </>
    )
}

export default AuthStateApp;