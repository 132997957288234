const baseConfig = {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    webClientId: process.env.REACT_APP_WEB_CLIENT,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    appSyncEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
    appSyncApiKey: process.env.REACT_APP_APPSYNC_API_KEY,
    uploadBucket: process.env.REACT_APP_UPLOAD_BUCKET,
    downloadBucket: process.env.REACT_APP_DOWNLOAD_BUCKET
}

export const AuthConfig = {
    identityPoolId: baseConfig.identityPoolId,
    region: baseConfig.region,
    userPoolId: baseConfig.userPoolId,
    userPoolWebClientId: baseConfig.webClientId,
    mandatorySignIn: false,
    // cookieStorage: {
    //     domain: 'pdf-parser-web.com',
    //     path: '/',
    //     expires: 365,
    //     sameSite: "strict",
    //     secure: true
    // },
    authenticationFlowType: 'USER_PASSWORD_AUTH',
}

export const AuthAppSync = {
    aws_appsync_graphqlEndpoint: baseConfig.appSyncEndpoint,
    aws_appsync_region: baseConfig.region,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
}

export const GuestAppSync = {
    aws_appsync_graphqlEndpoint: baseConfig.appSyncEndpoint,
    aws_appsync_region: baseConfig.region,
    aws_appsync_authenticationType: 'AWS_IAM',
    aws_appsync_apiKey: baseConfig.appSyncApiKey,
}

export const UploadBucketConfig = {
    Storage: {
        AWSS3: {
            bucket: baseConfig.uploadBucket,
            region: baseConfig.region,
            customPrefix: {
                public: '',
                protected: '',
                private: ''
            },
        }
    }
}

export const DownloadBucketConfig = {
    Storage: {
        AWSS3: {
            bucket: baseConfig.downloadBucket,
            region: baseConfig.region,
            customPrefix: {
                public: '',
                protected: '',
                private: ''
            },
        }
    }
}