export const getTaskHistoryQuery = `
    query getTaskHistory(
        $from: String
        $to: String
        $limit: Int
        $nextToken: String

    ) {
        getTaskHistory(from: $from, to: $to, limit: $limit, nextToken: $nextToken) {
            tasks {
                status 
                message 
                jsonPending 
                convertedFilePath 
                Error 
                batchFolder 
                exportBucket 
                files 
                jsonCompleted 
                logId 
                rootFolder 
                userId
                cfMetaData {
                    key
                    size
                    lastModified
                } 
            }
            nextToken
            scannedCount
        }
    }
`