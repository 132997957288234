import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { sendHelpEmail } from '../../graphql/amplify-api';
import * as bulmaToast from 'bulma-toast';
import { I18n } from 'aws-amplify';

const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const ErrorMessgae = ({ errorMessage }) => {
    return (
        <div style={{
            marginTop: '1%',
            backgroundColor: '#F5F3F2'
        }}>
            <span id="nameInputValidate" className="has-text-danger-dark">{errorMessage}</span>
        </div>
    )
}

const ContactForm = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [nameError, setNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [messageError, setMessageError] = useState('')


    useEffect(() => {
        bulmaToast.setDefaults({
            duration: 4000,
            position: 'bottom-center',
            closeOnClick: true,
        })
    }, [])

    const resetForm = () => {
        document.getElementById('nameInput').value = ''
        document.getElementById('emailInput').value = ''
        document.getElementById('messageInput').value = ''

        setNameError('')
        setEmailError('')
        setMessageError('')
    }

    const submitForm = async () => {
        //console.log'function called')
        const nameInput = document.getElementById('nameInput').value
        const emailInput = document.getElementById('emailInput').value
        const message = document.getElementById('messageInput').value
        
        if (!nameInput) {
            setNameError(I18n.get('Name field is required!'))
        } else {
            if (nameInput.length) {
                setNameError('')
                //console.log'name received', nameInput)
            } else {
                setNameError(I18n.get('Invalid name!'))
            }
        }

        if (!emailInput) {
            setEmailError(I18n.get('Email field is required!'))
        } else {
            if (emailInput.length && validateEmail(emailInput)) {
                setEmail(emailInput)
                setEmailError('')
                //console.log'email received', emailInput)
            } else {
                setEmailError(I18n.get('Invalid email!'))
            }
        }

        if (!message) {
            setMessageError(I18n.get('Can not send blank message!'))
        } else {
            if (message.length) {
                setMessage(message)
                setMessageError('')
            } else {
                setMessageError(I18n.get('Invalid message!'))
            }
        }


        if (nameInput && emailInput && message) {
            await sendHelpEmail({
                to: 'admin@pdftodata.com',
                from: emailInput,
                subject: 'Help Desk PDF to Data',
                name: nameInput,
                body: message
            })
                .then(d => {
                    bulmaToast.setDoc(window.document)
                    bulmaToast.toast({
                        message: I18n.get(`We received your message!`),
                        type: 'is-success',
                        extraClasses: 'is-size-5 has-text-centered has-background-success-light has-text-success-dark',
                        dismissible: true,
                        animate: { in: 'fadeIn', out: 'fadeOut' }
                    })
                    resetForm()
                })
        } 


    }
    return (
        <div style={{padding: '5%'}}>
            <div className="field">
                <label className="label">{I18n.get('Name')}</label>
                <div className="control">
                    <input id="nameInput" className="input" type="text" placeholder={I18n.get('Name Input')}/>
                    <ErrorMessgae errorMessage={nameError}/>
                </div>
            </div>
            <div className="field">
                <label className="label">{I18n.get('Email')}</label>
                <div className="control">
                    <input id="emailInput" className="input" type="email" placeholder={I18n.get('Email Input')} />
                    <ErrorMessgae errorMessage={emailError}/>
                </div>
            </div>
            <div className="field">
                <label className="label">{I18n.get('Message')}</label>
                <div className="control">
                    <textarea id="messageInput" className="textarea" placeholder=""></textarea>
                    <ErrorMessgae errorMessage={messageError}/>
                </div>
            </div>
            <div className="field is-grouped">
                <div className="control">
                    <button className="button custom-red-bg has-text-white" style={{ backgroundColor: '#FF5A5F'}}onClick={submitForm}>{I18n.get('Submit')}</button>
                </div>
                <div className="control">
                    <button className="button is-gray is-light" onClick={resetForm}>{I18n.get('Reset')}</button>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;