import React, { useEffect, useState } from "react";
import {
    Route,
    Redirect
} from 'react-router-dom';
import { getCurrentSession } from '../../auth/get-current-session'
import Loading from "../loader/loading";

const PrivateRoute = ({ path, children, defaultRedirect }) => {
    const [allow, setAllow] = useState(false)
    const [fetchingSession, setFetchingSession] = useState(true)
    
    useEffect(() => {
        return (async () => {
            const { session, user } = await getCurrentSession()
            if (session) {
                setAllow(true)
                setFetchingSession(false)
            } else {
                setAllow(false)
                setFetchingSession(false)
            }
        })()
    }, [allow])
    return (
        <Route exact path={path}>
            {
                allow === true
                    ? children
                    : fetchingSession === true
                        ? <Loading></Loading>
                        : <Redirect to="/home"></Redirect>
            }
        </Route>
    )
}

export default PrivateRoute