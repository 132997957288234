import React from 'react';
import whatsappLogo from '../../images/whatsapp-logo.png'

function WhatsappFloatLink(props) {
    return (
        <>
            <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5521959200687" target="_blank" id="whatsapp-link">
                <img src={whatsappLogo} className="float" />
            </a>
        </>
    );
}

export default WhatsappFloatLink;