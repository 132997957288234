import React, { useEffect, useState } from 'react';
import Navbar from '../components/navbar/navbar-protected';
import NavbarGuest from '../components/navbar/navbar-guset';
import Footer from '../components/footer/footer';
import ContactForm from '../components/contact-form/contact-form';
import whatsappLogo from '../images/whatsapp-logo.png';
import { useSelector } from 'react-redux';
import { I18n } from 'aws-amplify';

const ContactUs = (props) => {
    const isAuthenticated = useSelector(state => state.userInfo.isAuthenticated)
    useEffect(() => {
        const anchor = document.querySelector('#contact-form')
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
        window.scroll({top: 0, behavior: 'smooth'});
    }, [])
    return (
        <div id="contact-form">
            {isAuthenticated === true
                ? <Navbar></Navbar>
                : <NavbarGuest></NavbarGuest>
            }

            <section className="hero">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title">
                            {I18n.get('Contact Us')}
                        </h1>
                        <div className="columns">
                          <div className="column is-one-third">
                          <div 
                        style={{
                            border: '1px solid #E5E3E1',
                            borderRadius: '20px',
                            marginTop: '2%'
                        }}
                        >
                            <center><p style={{ marginTop: '-2.5%', backgroundColor: 'white', maxWidth: '50%' }} className="subtitle">
                                {I18n.get('Contact Us from Email')}
                            </p></center>
                            <ContactForm />
                        </div>
                        <div 
                        style={{
                            border: '1px solid #E5E3E1',
                            borderRadius: '20px',
                            marginTop: '2%'
                        }}
                        >
                            <center><p style={{ marginTop: '-2.5%', backgroundColor: 'white', maxWidth: '70%' }} className="subtitle">
                                {I18n.get('Or Contact Us from Whatsapp')}
                            </p></center>
                            <center>
                                <button className="button is-large is-dark has-text-white " style={{ margin: '8%' }} onClick={() => {
                                    // window.location.href = 
                                    window.open('https://api.whatsapp.com/send?1=pt_BR&phone=5521959200687', '_blank')
                                }}>
                                    <img src={whatsappLogo} width="35" />
                                    &nbsp;
                                    Whatsapp
                                </button>
                            </center>
                        </div>
                          </div>
                          
                        </div>
                        
                    </div>
                </div>
            </section>

            <div style={{ marginTop: '6rem' }}><Footer></Footer></div>
        </div>
    );
}

export default ContactUs;