import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store/store';
import { Provider } from 'react-redux';
import 'bulma/css/bulma.css';
// import Browser

ReactDOM.render(
  <Provider store={store}>
      <App></App>
  </Provider>,
  document.getElementById('root')
);
