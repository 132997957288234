export const getFileCountsQuery = `
    query getFileCounts(
        $from: String!
        $to: String!
    ) {
        getFileCounts(from: $from, to: $to) {
            allFiles
            completed
            failed
        }
    }
`