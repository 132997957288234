export const checkExecutionStatusQuery = `
    query checkExecutionStatus(
        $executionARN: String!
    ) {
        checkExecutionStatus(executionARN: $executionARN) {
            status
            message
            jsonPending
            convertedFilePath
            Error
        }
    }
`