import React, { useEffect, useState } from 'react';
import './footer.css';
import footerLogo from '../../images/footerLogo.png'
import { Link } from 'react-router-dom';
import { cleanup } from '../../store/upload-info';
import { useDispatch } from 'react-redux';
import { routes } from '../../router/routes';
import { I18n } from 'aws-amplify';
import { isSetLanguage } from '../../store/user-info';

const Footer = (props) => {
    const dispatch = useDispatch()
    const [hashPath, setHashPath] = useState(window.location.hash.split('#')[1])
    const [navTo, setNavTo] = useState('')

    useEffect(() => {
        const flag = hashPath.split('/').filter(e => e !== '')
        //console.log'Footer rendered', hashPath, flag, flag.length)
        if (flag[0] === "app") {
            setNavTo(routes.appHome)
        } else {
            setNavTo(routes.home)
        }
    }, [hashPath])
    return (
        <>
            <div >
                <footer className="footer has-background-light" style={{ paddingBottom: '3rem', backgroundColor: 'black' }} >
                    <div className="container">
                        <div className="columns">
                            <div className="column is-one-fifth">
                                <Link style={{ textDecoration: 'none' }} to={navTo} onClick={() => {
                                    // const anchor = document.querySelector('#red-wrapper-section')
                                    // anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                    dispatch(cleanup())
                                    window.location = '/'
                                }}>
                                    <img src={footerLogo}></img>
                                </Link>
                            </div>
                            <div className="column is-four-fifth">
                                <div className="content has-text-center">
                                    <p className="is-size-4 is-uppercase has-text-weight-normal has-text-dark">{I18n.get('QUICK LINKS')}</p>
                                </div>
                                <div className="columns">
                                    <div className="column is-one-fifth">
                                        <div className="content is-small">
                                            <p className="is-size-5 has-text-grey">
                                                <Link style={{ textDecoration: 'none' }} className="is-size-5 has-text-grey" to={navTo} onClick={() => {
                                                    // const anchor = document.querySelector('#red-wrapper-section')
                                                    // anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                                    dispatch(cleanup())
                                                    window.location = '/'
                                                }}>{I18n.get('Home')}</Link>
                                            </p>
                                            {/* <p className="is-size-5 has-text-grey">Convert</p> */}
                                            <Link style={{ textDecoration: 'none' }} to={navTo + '/pricing'} onClick={() => {
                                                //console.lognavTo + '/pricing')
                                                // const anchor = document.querySelector('#pricing-table-div')
                                                // anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                            }}>
                                                <p className="is-size-5 has-text-grey">{I18n.get('Pricing')}</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="column is-full">
                                        <div className="content is-small">
                                            {/* <p className="is-size-5 has-text-grey">API Documentation</p> */}
                                            <p className="is-size-5 has-text-grey">
                                                <Link style={{ textDecoration: 'none' }} className="is-size-5 has-text-grey" to={navTo + '/contactus'}>{I18n.get('Contact Us/ Report a bug')}</Link>
                                            </p>
                                            {/* <p className="is-size-5 has-text-grey">
                                            Contact Us/ Report a bug
                                            </p> */}

                                            <p className="is-size-5 has-text-grey">
                                            <Link style={{ textDecoration: 'none' }} className="is-size-5 has-text-grey" to={navTo + '/faqs'}>{I18n.get('FAQs')}</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns" style={{ marginTop: '2%', maxHeight: '2%', borderTop: '1px solid #7070703D ' }}>
                            {/* <div className="column is-one-fifth">
                                <p className="is-size-5 has-text-grey">Privacy Policy</p>
                            </div>
                            <div className="column is-one-fifth">
                                <p className="is-size-5 has-text-grey">Terms & Condition</p>
                            </div> */}

                        </div>
                        {/* <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'right',
                            justifyItems: 'center',
                            alignContent: 'right',
                            alignItems: 'center',
                            marginTop: '1%',
                        }}>

                            <p className="is-size-5 has-text-grey" >Change Language </p>&nbsp;&nbsp;<button onClick={() => {
                                I18n.setLanguage('en')
                                //console.log'Language updated', 'English')
                                dispatch(isSetLanguage('en'))
                            }} className="button is-dark is-rounded">English</button>&nbsp;&nbsp; <button onClick={() => {
                                I18n.setLanguage('pt')
                                //console.log"Language updated", 'Portuguese')
                                dispatch(isSetLanguage('pt'))
                            }} className="button is-dark is-rounded">Portuguese</button>
                        </div> */}
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Footer;