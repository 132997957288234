import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/navLogo.png';
import './navbar-guest.css';
import { cleanup } from '../../store/upload-info';
import { useDispatch } from 'react-redux';

const Navbar = () => {
    const dispatch = useDispatch()
    
    useEffect(() => {
        (function () {
            var burger = document.querySelector('.navbar-burger');
            var menu = document.querySelector('#' + burger.dataset.target);
            burger.addEventListener('click', function () {
                burger.classList.toggle('is-active');
                menu.classList.toggle('is-active');
            });
        })();

    }, [])

    return (
        <>
            {/* <nav className="navbar" style={{ boxShadow: '1px 0px 9px 1px #DCDCDC', paddingTop: '1%', paddingBottom: '1%' }}> */}
            <nav className="navbar" style={{ paddingTop: '1%', paddingBottom: '1%' }}>
                <div className="container">
                    <div className="navbar-brand">
                        <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/" onClick={() => {
                            const anchor = document.querySelector('#red-wrapper-section')
                            dispatch(cleanup())
                            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                            window.location = '/'
                        }}>
                            <img
                                className="navbar-logo"
                                src={logo}
                            />
                        </Link>
                        <span className="navbar-burger burger" data-target="navbarMenuHeroB">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </div>
                    <div id="navbarMenuHeroB" className="navbar-menu">
                        <div className="navbar-end">
                            {/* <div className="navbar-item">
                                <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/">
                                    PDF Capture
                                </Link>
                            </div>
                            <div className="navbar-item">
                                <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/">
                                    GD Consulting
                                </Link>
                            </div>
                            <div className="navbar-item">
                                <Link style={{ textDecoration: 'none' }} className="navbar-item" to="/">
                                    API
                                </Link>
                            </div> */}
                            <div className="navbar-item">
                                <Link style={{ textDecoration: 'none' }} onMouseOver={(e) => {
                                    e.target.classList.remove('has-text-danger')
                                }} onMouseOut={(e) => {
                                    e.target.classList.add('has-text-danger')
                                }} className="button has-text-danger is-rounded is-danger is-outlined" to="/home/signin">Sign In</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;